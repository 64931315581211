import { graphql, useStaticQuery, Link } from "gatsby"
import React from "react"
import Img from "gatsby-image"

export default ({page, imagePath, title, button, children, className}) => {
	const data = useStaticQuery(graphql`
		query {
			allFile(filter: {relativePath: {regex: "/cards/"}}) {
				nodes {
					relativePath
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
  `);
	const image = data.allFile.nodes.find(node => node.relativePath.includes(imagePath));
  if (button) {
		return (
			<div className={"card " + className}>
				<Img fluid={image.childImageSharp.fluid} alt=""/>
				<h2>{title}</h2>
				<p>
					{children}
				</p>
				<Link className="button cta" aria-label={"Book " + title + " now"} to={page}>{button}</Link>
			</div>
		)
	} else {
		if (page[0] === '/') {
			return (
				<Link to={page} className={"card " + className} >
					<Img fluid={image.childImageSharp.fluid} alt=""/>
					<h2>{title}</h2>
					<p>
						{children}
					</p>
				</Link>
			)
		} else {
			return (
				<a href={page} className={"card " + className} >
					<Img fluid={image.childImageSharp.fluid} alt=""/>
					<h2>{title}</h2>
					<p>
						{children}
					</p>
				</a>
			)
		}
	}
}