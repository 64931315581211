import React, { useEffect } from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Header from "../components/Header"
import Card from "../components/Card"
import animationObserver from "../helpers/animationObserver"

const bgImage = "/header.jpg"

export default () => {
	useEffect(() => {
    animationObserver('.card');
  }, []);
	return (
		<Layout>
			<SEO pageTitle="Treatments" />
			<Header pageTitle="Treatments" bgImage={bgImage} />
			<section className="intro-text">
				<div className="container">
					<p>If your brows are as important to you as they are to me, then you are in the right place.  I’m proud to not only be an award winning brow stylist, but the ONLY Elite HD Brows stylist in the whole of the East Midlands, so your brows are in good hands here, whilst all types of brows are my signature treatments, why not check out some of the other treatments that my clients keep coming back for time after time.</p>
				</div>
			</section>
			<section className="treatments grey-bg">
				<div className="container">
					<div className="row">
						<div className="col">
							<Card page="/treatments/brows" imagePath="cards/cardimg.jpg" title="Brow treatments" className="one">
								<p>Summary of brow treatments.</p>
							</Card>
						</div>
						<div className="col">
							<Card page="/treatments/lashes" imagePath="cards/cardimg.jpg" title="Lash treatments" className="two">
								<p>Summary of lash treatments.</p>
							</Card>
						</div>
						<div className="col">
							<Card page="/treatments/face" imagePath="cards/cardimg.jpg" title="Face treatments" className="three">
								<p>Summary of face treatments.</p>
							</Card>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	)
}