export default (selectors) => {
	const animatedItems = document.querySelectorAll(selectors);  
	if (!!window.IntersectionObserver) {
		const animationCallback = entries => {
			for (const entry of entries) {
				if (entry.isIntersecting) {
					entry.target.classList.add('show');
					animationObserver.unobserve(entry.target);
				}
			}
		};
		const animationObserver = new IntersectionObserver(animationCallback, {
			threshold: 0.3
		});
		for (const animatedItem of animatedItems) {
			animationObserver.observe(animatedItem);
		}
	} else {
		for (const animatedItem of animatedItems) {
			animatedItem.classList.add('show');
		}
	}
}